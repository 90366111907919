import type { ReactElement } from 'react';
import { animatedBannerText } from './utils/constants';

function AnimatedBanner(): ReactElement {
  return (
    <div className="c-animated-banner-wrapper">
      <div className="c-animated-banner">
        <div className="banner">
          <div className="banner-content">
            {animatedBannerText.map((blurb) => (
              <>
                <div className="text" key={blurb}>
                  {blurb}
                </div>
                <div className="bullet">•</div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AnimatedBanner;
