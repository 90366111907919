import highProteinUrl from 'consumer/pages/react-home/featured-highlights/high-protein.jpg';
import glutenFreeUrl from 'consumer/pages/react-home/featured-highlights/gluten-free.jpg';
import lessThan600CaloriesUrl from 'consumer/pages/react-home/featured-highlights/less-than-600-calories.jpg';
import sodiumSmartUrl from 'consumer/pages/react-home/featured-highlights/low-sodium.jpg';
import lessPrepUrl from 'consumer/pages/react-home/featured-highlights/less-prep.jpg';
import groceryUrl from 'consumer/pages/react-home/featured-highlights/grocery.jpg';

class RecipeFilters {
    static high_protein = {
      label: 'High Protein',
      slug: 'high-protein',
      key: 'high_protein',
      name: 'High-Protein',
      imageUrl: highProteinUrl,
      goalBlurb: 'More Energy',
      url: '/weekly-menu?weeks-ago=0&preferences=high-protein',
    };

    static gluten_free = {
      label: 'Gluten Free',
      slug: 'gluten-free',
      key: 'gluten_free',
      name: 'Gluten-Free',
      imageUrl: glutenFreeUrl,
      goalBlurb: 'Gut Health',
      url: '/weekly-menu?weeks-ago=0&preferences=gluten-free',
    };

    static less_than_600_calories = {
      label: 'Less than 600 Calories',
      slug: 'less-than-600-calories',
      key: '600-calories',
      name: '<600 Calories',
      imageUrl: lessThan600CaloriesUrl,
      goalBlurb: 'Weight Management',
      url: '/weekly-menu?weeks-ago=0&preferences=less-than-600-calories',
    };

    static sodium_smart = {
      label: 'Sodium Smart',
      slug: 'low-sodium',
      key: 'low-sodium',
      name: 'Low Sodium',
      imageUrl: sodiumSmartUrl,
      goalBlurb: 'Heart Health',
      url: '/weekly-menu?weeks-ago=0&preferences=low-sodium',
    };

    static less_prep = {
      label: 'Less Prep',
      slug: 'quick-and-easy',
      key: 'quick-and-easy',
      name: 'Less Prep',
      imageUrl: lessPrepUrl,
      goalBlurb: 'Clean Weekday Eating',
      url: '/weekly-menu?weeks-ago=0&preferences=quick-and-easy',
    };

    static grocery = {
      label: 'Grocery',
      slug: 'grocery',
      key: 'grocery',
      name: 'Grocery',
      imageUrl: groceryUrl,
      goalBlurb: 'Enjoy Plant- Based Grocery',
      url: '/weekly-menu/grocery',
    };

    static preferenceFilterSelectionItems =
        [RecipeFilters.high_protein,
          RecipeFilters.gluten_free,
          RecipeFilters.less_than_600_calories,
          RecipeFilters.sodium_smart,
          RecipeFilters.less_prep,
        ]

    static goalItems =
        [RecipeFilters.high_protein,
          RecipeFilters.gluten_free,
          RecipeFilters.less_than_600_calories,
          RecipeFilters.sodium_smart,
          RecipeFilters.less_prep,
          RecipeFilters.grocery,
        ]

    static allItems =
        [RecipeFilters.high_protein,
          RecipeFilters.gluten_free,
          RecipeFilters.less_than_600_calories,
          RecipeFilters.sodium_smart,
          RecipeFilters.less_prep,
          RecipeFilters.grocery,
        ]

    static getNameBySlug(slug: string) {
      const preference = RecipeFilters
        .allItems
        .find((pref) => pref.slug === slug);
      return preference ? preference.name : null;
    }

    static getPreferenceBySlug(slug: string) {
      return RecipeFilters
        .allItems
        .find((pref) => pref.slug === slug);
    }
}
export default RecipeFilters;
