import type { ReactElement } from 'react';
import FeatureHighlights from './FeatureHighlights';
import AnimatedBanner from './AnimatedBanner';

/*
* TODO: All components built as part of the PP-11839 (Homepage Redesign) epic will be added here.
*  All of the child components should live within this flex_box/components/Homepage directory.
*  We should organize them in subdirectories as appropriate (e.g. components/Homepage/Hero
*  or components/Homepage/CustomerGoals)
* */
function Homepage(): ReactElement {
  return (
    <>
      <FeatureHighlights />
      <AnimatedBanner />
    </>

  );
}

export default Homepage;
