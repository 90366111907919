// Duplicated strings in animatedBannerText to allow continuous shifting
export const animatedBannerText = [
  'GOOD FOOD YOUR WAY',
  'HIGH PROTEIN',
  'LOW CALORIE',
  'GLUTEN-FREE',
  'UNDER 600 CALORIES',
  'GOOD FOOD YOUR WAY',
  'HIGH PROTEIN',
  'LOW CALORIE',
  'GLUTEN-FREE',
  'UNDER 600 CALORIES',
  'GOOD FOOD YOUR WAY',
  'HIGH PROTEIN',
  'LOW CALORIE',
  'GLUTEN-FREE',
  'UNDER 600 CALORIES'];
